import React from 'react';

import { Layout } from 'containers/layout/Layout';
import { Header } from 'components/header/Header';
import { Footer } from 'components/footer/Footer';
import { Tagline } from 'components/tagline/Tagline';
import { IconButton } from 'components/button/IconButton';
import { BackgroundSection } from 'components/background/BackgroundSection';

import s from './Error.module.scss';

type Props = {
  statusCode: number;
  title: string;
};

export const Error = ({ statusCode, title }: Props) => {
  return (
    <Layout header={<Header color="brand" />} footer={<Footer />}>
      <BackgroundSection color="dark">
        <div className={s.error}>
          <div className={s.error__container}>
            <div className={s.error__content}>
              <div className={s.error__row}>
                <div className={s.error__column}>
                  {statusCode && <Tagline text={`${statusCode} Error`} />}
                  <div className={s.error__title}>{title}</div>
                  <div className={s.error__text}>
                    We’re sorry, but the page that you are looking for does not exist. Looking for
                    something in particular? Check the menu.
                  </div>
                  <div className={s.error__button}>
                    <IconButton color="brand" hover="brand" href="/">
                      Back to homepage
                    </IconButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </BackgroundSection>
    </Layout>
  );
};
